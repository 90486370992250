import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Button, Spinner } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import StakingABI from '../abis/StakingABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import logoToken from '../logoToken.svg';
import { CustomToast, useCustomToast } from '../components/CustomToast';


const STAKE_DURATIONS = {
    "ONE_MONTH": [2592000, 2.5, 30],  // 2.5% per month, ~30% APY
    "THREE_MONTHS": [7776000, 10, 40], // 10% per 3 months, ~40% APY
    "SIX_MONTHS": [15552000, 40, 80], // 40% per 6 months, ~80% APY
    "ONE_YEAR": [31536000, 120, 120], // 120% per year, 120% APY
    "TWO_YEARS": [63072000, 400, 200], // 400% per 2 years, 200% APY
    "FIVE_YEARS": [157680000, 1500, 300], // 1500% per 5 years, 300% APY
};
const Staking = ({ provider, account, isConnected }) => {
    const [tokenBalance, setTokenBalance] = useState('0');
    const [stakes, setStakes] = useState([]);
    const [amountToStake, setAmountToStake] = useState('');
    const [durationToStake, setDurationToStake] = useState("ONE_MONTH");
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    const [stakedAmount, setStakedAmount] = useState('0');
    const { toast, showToast, hideToast } = useCustomToast();
    async function getBalance() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));
    }

    const getStakeDurationName = (firstValue) => {
        for (const [key, value] of Object.entries(STAKE_DURATIONS)) {
            if (value[0] === firstValue) {
                return key;
            }
        }
        return null; // or some default value
    };
    useEffect(() => {
        if (!provider || !account) return;
        fetchStakes();
        getBalance();
    }, [provider, account]);
    const fetchStakes = async () => {
        setLoading(true);
        try {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
            const stakesDataUser = await contract.getUserStakes(account);
            const serialized = JSON.stringify(stakesDataUser, (key, value) =>
                typeof value === 'bigint' ? value.toString() : value
            );
            const stakesData = JSON.parse(serialized);
            let stakesArr = [];
            for (let i = 0; i < stakesData.length; i++) {
                const stake = stakesData[i];
                const rewardPercent = STAKE_DURATIONS[getStakeDurationName(parseFloat(stake["2"]))][1];
                const amount = formatUnits(stake["0"].toString(), 18);
                const rewardAmount = parseFloat(amount) + (parseFloat(amount) / 100) * rewardPercent;
                stakesArr.push({
                    amount: amount,
                    rewardAmount: rewardAmount,
                    timestamp: stake["1"],
                    duration: stake["2"],
                    claimed: stake["3"].claimed,
                    i
                });
            }
            setStakes(stakesArr);
        } catch (error) {
            console.error("Failed to fetch stakes", error);
        } finally {
            setLoading(false);
        }
    };

    const handleStake = async () => {

        if (!provider || !account) return;
        try {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            setLoading(true);
            setTxMessage("Approving "+SETTINGS.tokenSymbol+" transaction...");
            const tokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
            const amountToStakeWei = parseUnits(amountToStake.toString(), 18);
            const approveTx = await tokenContract.approve(SETTINGS.stakingContract, amountToStakeWei);
            await approveTx.wait();
            setTxMessage("Staking "+SETTINGS.tokenSymbol+" tokens...");
            const stakingContract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
            const stakeTx = await stakingContract.stake(amountToStakeWei, STAKE_DURATIONS[durationToStake][0]);
            await stakeTx.wait();
            setTxMessage(""+SETTINGS.tokenSymbol+" staked successfully!");
            await fetchStakes();
            await getBalance();
            setAmountToStake('');
        } catch (error) {
            console.error("Staking failed", error);
            showToast("Staking failed. Please try again.", "danger");
            setTxMessage("Staking failed. Please try again.");
        } finally {
            setLoading(false);
            showToast("Stake successfull!", "success");
            setTimeout(() => setTxMessage(""), 5000);
        }
    };

    const handleClaim = async (stakeIndex) => {
        if (!provider || !account) return;
        try {
            setLoading(true);
            setTxMessage("Claiming rewards...");
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.stakingContract, StakingABI, signer);
            await contract.claim(stakeIndex);
            setTxMessage("Rewards successfully claimed!");
            await fetchStakes();
        } catch (error) {
            console.error("Claiming failed", error);
            showToast("Claiming failed. Please try again.", "danger");
            setTxMessage("Claiming failed. Please try again.");
        } finally {
            setLoading(false);
            showToast("Claim successfull!", "success");
            setTimeout(() => setTxMessage(""), 5000);

        }
    };

    const getProgress = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        const timePassed = now - timestamp;
        const progress = (timePassed / duration) * 100;
        return Math.min(progress, 100);
    };

    const getTimeLeft = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        const timePassed = now - timestamp;
        const timeLeft = duration - timePassed;

        if (timeLeft <= 0) {
            return "Time is up";
        }

        const days = Math.floor(timeLeft / (24 * 3600));
        const hours = Math.floor((timeLeft % (24 * 3600)) / 3600);
        const minutes = Math.floor((timeLeft % 3600) / 60);

        return `${days} days, ${hours} hours, and ${minutes} minutes left`;
    };

    const isClaimable = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        return (now - timestamp) >= duration;
    };

    const calculateReward = (amount, duration) => {
        const rewardPercent = STAKE_DURATIONS[duration][1];
        return (parseFloat(amount) / 100) * rewardPercent;
    };

    const getAPY = (duration) => {
        return STAKE_DURATIONS[duration][2];
    };

    const isStakeButtonDisabled = () => {
        if (isNaN(parseFloat(amountToStake)) || amountToStake <= 0) {
            return true;
        }
        return false;
    };

    if (loading) {
        return (
            <div className="loaderScreen text-center">
                <br />
                <Spinner animation="border" role="status" className='loaderBig' /><br />

                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }

    return (
        <div>
            <CustomToast show={toast.show} message={toast.message} type={toast.type} onClose={hideToast} />
            <Row className='pt-md-4 pt-2'>
                <Col sm={12} md={8} lg={6} className='offset-lg-0 offset-md-2 offset-0'>
                    <div className="swap-form pokemon-card additional-p">
                        <div className="buy-token-header text-center">
                            <h2 style={{ textAlign: "center" }}>Stake {SETTINGS.tokenSymbol}</h2>
                        </div>
                       
                        <div className="input-group mt-2" style={{ marginBottom: "14px" }}>
                            <p className='mb-1 p small'>Enter amount:</p>
                            <input
                                type="number"
                                value={amountToStake}
                                onChange={(e) => setAmountToStake(e.target.value)}
                                placeholder="Amount to stake"
                                className='custom-select'
                            />
                            <center>
                                <span style={{ marginTop: "6px", marginBottom: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", fontWeight: "800" }}>
                                    <small>Your balance:</small>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "10px" }} alt={SETTINGS.tokenSymbol} />
                                        <small>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenBalance)} {SETTINGS.tokenSymbol}</small>
                                    </div>
                                    <Button variant="primary" onClick={(e) => setAmountToStake(tokenBalance)} className='buton smallBtn buton-secondary d-sm-block d-none ms-2' style={{ fontSize: "10px", width: "70px", padding: "3px 5px", marginLeft:"10px" }}>
                                        Max
                                    </Button>
                                </span>
                            </center>
                            <Button variant="primary" onClick={(e) => setAmountToStake(tokenBalance)} className='buton smallBtn buton-secondary d-sm-none d-block mt-1 mb-2' style={{ fontSize: "10px", width: "100px", marginLeft:"10px" }}>
                                Max
                            </Button>

                        </div>
                        <div className="input-group mb-3 pb-1">
                            <p className='mb-1 p small'>Select duration:</p>
                            <select
                                value={durationToStake}
                                onChange={(e) => setDurationToStake(e.target.value)}
                                className='custom-select'
                            >
                                <option value="ONE_MONTH">1 Month (2.5%) 30% APY</option>
                                <option value="THREE_MONTHS">3 Months (10%) 40% APY</option>
                                <option value="SIX_MONTHS">6 Months (40%) 80% APY</option>
                                <option value="ONE_YEAR">1 Year (120%) 120% APY</option>
                                <option value="TWO_YEARS">2 Years (400%) 200% APY</option>
                                <option value="FIVE_YEARS">5 Years (1500%) 300% APY</option>
                            </select>
                        </div>
                        {/* Info */}
                        <div className="pokemon-card-footer pt-0">
                            <Row className="mb-4" style={{ fontSize: "14px" }}>
                                <div className="d-flex align-items-center mt-1">
                                    <p className='mb-0'><b>Stake size:</b></p>
                                    <img src={logoToken} className='tokenIconColor' style={{ width: "18px", marginRight: "6px", marginLeft: "7px" }} alt={SETTINGS.tokenSymbol} />
                                    <b className="ml-1">
                                        {isNaN(parseFloat(amountToStake)) ? (<>0 </>
                                        ) : (
                                            <>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(amountToStake) + calculateReward(amountToStake, durationToStake))} </>
                                        )}
                                        {SETTINGS.tokenSymbol}
                                    </b>
                                </div>

                                <div className="d-flex align-items-center mt-1 small">
                                    <p className='mb-0'>You are staking:</p>
                                    <b className="ms-2" style={{ fontWeight: "400" }}>
                                        {isNaN(parseFloat(amountToStake)) ? (<>0 </>
                                        ) : (
                                            <>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amountToStake)}  </>
                                        )}
                                        {SETTINGS.tokenSymbol}
                                    </b>
                                </div>
                                <div className="d-flex align-items-center small">
                                    <p className='mb-0'>Stake reward:</p>
                                    <b className="ms-2" style={{ fontWeight: "400" }}>
                                        {isNaN(parseFloat(amountToStake)) ? (<>0 </>
                                        ) : (
                                            <>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculateReward(amountToStake, durationToStake))}  </>
                                        )}
                                        {SETTINGS.tokenSymbol}
                                    </b>
                                </div>
                                <div className="d-flex align-items-center mt-3">
                                    <b className='mb-0'>
                                        APY(%):
                                        <b className='ms-2'>{getAPY(durationToStake)} %</b>
                                    </b>
                                </div>

                            </Row>
                        </div>
                        {/* Button */}
                        <Button
                            variant="success"
                            className='buton mt-2'
                            onClick={handleStake}
                            disabled={isStakeButtonDisabled()}
                        >
                            STAKE {SETTINGS.tokenSymbol}
                        </Button>
                        {isStakeButtonDisabled() && (
                            <>
                                {!isNaN(parseFloat(amountToStake)) && (
                                    <div className="alert alert-warning mt-3" role="alert">
                                        {parseFloat(tokenBalance) <= parseFloat(amountToStake) ? (
                                            <>You do not have enough balance to stake this amount.</>
                                        ) : (
                                            <>Not enough tokens in the reward pool to cover this stake.</>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Col>

                <Col sm={12} md={12} lg={1} style={{ maxWidth: "40px" }}></Col>

                {/* Your stakes */}
                <Col sm={12} md={8} lg={5} className='offset-lg-0 offset-md-2 offset-0 mb-3'>
                    {stakes.length > 0 && <h4 className='pt-lg-0 pt-2 mb-3'>Your Stakes</h4>}
                    {stakes.map((stake, index) => (
                        <div key={index} className="mb-3 pokemon-card additional-p">
                            <Row>
                                <Col xs={6}>
                                    <p className='mb-2 p small'>Stake size:</p>
                                    <h6>
                                        <img src={logoToken} className='tokenIconColor' style={{ width: "25px", marginRight: "7px", marginTop: "-3px" }} alt={SETTINGS.tokenSymbol} />
                                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.rewardAmount)}  {SETTINGS.tokenSymbol}
                                    </h6>
                                </Col>
                                <Col xs={6}>
                                    <p className='mb-2 p small'>Staked:</p>
                                    <h6>
                                        <img src={logoToken} className='tokenIconColor' style={{ width: "25px", marginRight: "7px", marginTop: "-3px" }} alt={SETTINGS.tokenSymbol} />
                                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.amount)} {SETTINGS.tokenSymbol}
                                    </h6>
                                </Col>
                            </Row>
                            <p className='mb-2 mt-3 p small'>Time left:</p>
                            <Col className='mb-4'>
                                <ProgressBar
                                    animated
                                    striped
                                    now={getProgress(stake.timestamp, stake.duration)}
                                    label={`${getProgress(stake.timestamp, stake.duration)}%`}
                                />
                                <div className="aria-valuenow mt-1" style={{ fontSize: "12px", fontWeight: "700" }}>
                                    {getTimeLeft(stake.timestamp, stake.duration)}
                                </div>
                            </Col>
                            <Col>
                                <Button
                                    variant="success"
                                    className="buton"
                                    onClick={() => handleClaim(index)}
                                    disabled={!isClaimable(stake.timestamp, stake.duration) || stake.claimed}
                                >
                                    {stake.claimed ? "Claimed" : (<>
                                        Claim
                                    </>)}
                                </Button>
                            </Col>
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    );
};

export default Staking;
